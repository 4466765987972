import React, { useEffect, useRef } from 'react';
import * as css from './whatsapp-button.styles';
import { MADESA_HOST_NAME, OLIST_HOST_NAME } from '@plug/config/consts';

const WhatsAppButton = () => {
  const allowed = [...MADESA_HOST_NAME, 'influencer.anapaulacarvalho.com'];
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  // Função para injetar o script do Tiny Go Hubly
  useEffect(() => {
    // Verifica se estamos em um domínio da Olist
    if (OLIST_HOST_NAME.includes(window.location.host)) {
      console.log('Injetando script do Tiny Go Hubly para domínio Olist');

      // Verifica se o script já existe para evitar duplicação
      if (!document.getElementById('tiny-go-hubly-contato-script')) {
        // Cria o elemento script
        const script = document.createElement('script');
        script.id = 'tiny-go-hubly-contato-script';
        script.src = 'https://arquivo.tiny.com.br/gohubbly/script.js';
        script.async = true;

        // Adiciona o script ao head
        document.head.appendChild(script);

        // Guarda a referência do script para remover depois
        scriptRef.current = script;

        console.log('Script do Tiny Go Hubly injetado com sucesso');
      }

      // Cleanup function - remove o script quando o componente é desmontado
      return () => {
        if (scriptRef.current && document.getElementById('tiny-go-hubly-contato-script')) {
          console.log('Removendo script do Tiny Go Hubly');
          document.head.removeChild(scriptRef.current);
          scriptRef.current = null;
        }
      };
    }

    // Não faz nada se não estamos em um domínio da Olist
    return undefined;
  }, []);

  // Para domínios da Olist, retornamos um fragment vazio
  // O script já foi injetado pelo useEffect acima
  if (OLIST_HOST_NAME.includes(window.location.host)) {
    return <></>;
  }

  if (!allowed.includes(window.location.host)) return <></>;

  const urls = {
    'afiliados.madesa.com':
      'https://api.whatsapp.com/send?l=pt_br&phone=5551991794303&botao=mensagem&text=Vim%20pela%20plataforma%20de%20afiliados%20e%20preciso%20de%20ajuda',
    'influencer.anapaulacarvalho.com': 'https://api.whatsapp.com/send?phone=558699057056',
  };

  return (
    <css.Container href={urls[window.location.hostname]} target="_blank">
      <css.Image
        width="58px"
        height="58px"
        src="https://arquivo.tiny.com.br/gohubbly/images/whatsapp.svg"
        alt="Botão WhatsApp"
      />
    </css.Container>
  );
};

export default WhatsAppButton;
