import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { ApolloProvider } from '@apollo/client';
import { getApolloClient, ApolloClientType } from '@plug/apollo';
import AuthProvider from '@plug/contexts/auth-context';
import Suspense from '@plug/components/suspense';
import CacheBuster from '@plug/components/cache-buster';
import BrowserRouter from '@plug/components/browser-router';
import routes from '@plug/routes/index';
import GlobalStyle from './styles/global';
import SnackbarContainer from '@plug/ui/snackbar';
import { ENVIRONMENT, IUGU_ACCOUNT_ID, NODE_ENV } from '@plug/config/env';
import TagManager from 'react-gtm-module';
import { ThemeStore } from './redux/store';
import useLocalStorage from './hooks/use-local-storage';
import {
  LS_PARTNER,
  NUVEMSHOP_LS_CODE,
  WHITELABEL_LS_KEY,
  WHITE_LABEL_TITLES,
} from './config/consts';
import i18n from './i18n';
import WhatsAppButton from './ui2/whatsapp-button';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';
const App: React.FC = () => {
  const [client, setClient] = useState<ApolloClientType | null>(null);
  const { get, set } = useLocalStorage();
  const params = new URLSearchParams(window.location.search);

  const tagManagerArgs = {
    prod: {
      gtmId: 'GTM-M9G4BKF',
    },
    dev: {
      gtmId: 'GTM-P3CD4KV',
    },
  };

  const loadClientAsync = async (): Promise<void> => {
    const client = await getApolloClient();

    setClient(client);
  };

  useEffect(() => {
    document.title = WHITE_LABEL_TITLES[window.location.hostname] ?? 'MONITFY';
  }, []);

  useEffect(() => {
    loadClientAsync();
    if (params.has('code')) {
      localStorage.setItem(NUVEMSHOP_LS_CODE, params.get('code'));
      localStorage.setItem(LS_PARTNER, params.get('partner'));
    }

    if (NODE_ENV === 'test' || NODE_ENV === 'development') {
      TagManager.initialize(tagManagerArgs.dev);
    } else {
      TagManager.initialize(tagManagerArgs.prod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ThemeStore.createReaction(
      s => s,
      state => {
        const fromLocalStorage: any = get('WHITE_LABEL_INFO');
        const stringfied = JSON.stringify(state);

        if (stringfied !== JSON.stringify(fromLocalStorage)) {
          set(WHITELABEL_LS_KEY, JSON.stringify({ ...fromLocalStorage, ...state }));
        }
      },
    );
  }, [get, set]);

  useEffect(() => {
    if (window.Iugu) {
      Iugu.setAccountID(IUGU_ACCOUNT_ID);

      if (ENVIRONMENT !== 'production') {
        Iugu.setTestMode(true);
      }
    }
  }, []);

  const handleLanguageChanged = async (lng: string) => {
    if (lng && i18n.languages.includes(lng)) {
      moment.locale(lng.toLowerCase());
    }
  };

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);

    return () => i18n.off('languageChanged', handleLanguageChanged);
  }, []);

  return (
    <>
      <SnackbarContainer />
      <WhatsAppButton />
      <CacheBuster>
        <Suspense>
          {client && (
            <ApolloProvider client={client}>
              <BrowserRouter routes={routes} providersWithRouter={AuthProvider as React.FC} />
            </ApolloProvider>
          )}
        </Suspense>
      </CacheBuster>
      <GlobalStyle />
    </>
  );
};

export default App;
