import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { useHistory, Switch, Route, useLocation } from 'react-router';
import Header from '@plug/components/header';
import { useAuthContext } from '@plug/contexts/auth-context';
import { useNavigationMenuContext } from '@plug/contexts/NavigationMenuContext';
import { LayoutProps } from '../route/types';
import ErrorBoundary from '../error-boundary';
import NavSidebar from '../nav-sidebar';
import {
  DashboardFlex,
  FloatingHeader,
  FloatingNavSidebar as FloatingNavSidebarStyled,
  NavSidebarWrapper,
} from './style';
import { HeaderWrapper } from '@plug/domains/auth/components/login-container-whitelabel/style';
import DashboardFooter from '../dashboard-footer';
import {
  ORGANIZATION_DETAILS,
  OrganizationDetailsQuery,
} from '../../graphql/queries/organization-details';
import { useQuery } from '@apollo/client';
import AdminGamification from '../admin-gamification';
import { CountdownContainerContextProvider } from '@plug/ui/countdown-container/context';
import { OLIST_HOST_NAME, ORGANIZATION_CUSTOMIZATION_KEY } from '@plug/config/consts';
import { getLocalInfo } from '@plug/helpers/local-info';
import FloatingNavSidebar from '../floating-nav-sidebar';
import { useMediaQuery } from 'react-responsive';
import useIsOrganizationAdmin from '@plug/hooks/use-is-organization-admin';
import isDesktop from '@plug/helpers/is-desktop';
import { Integrations } from '@plug/graphql/types';
import { SHOWCASE_PATH } from '@plug/domains/organization/domains/affiliate/routing';
import { getBraspagBankStatus } from '@plug/helpers/mary-kay';

export const NAV_SIDEBAR_HEIGHT = '88px';
export const NAV_SIDEBAR_HEIGHT_MOBILE = '72px';
export const MENU_HEIGHT_MOBILE = '104px';
export const NAV_SIDEBAR_WIDTH = '260px';

const DashboardLayout: LayoutProps = props => {
  const { title, hideHeaderTitle, routes } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const history = useHistory();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const { currentOrganization, selectCurrentOrganization, pendencies = [] } = useAuthContext();
  const { openedNavigationMenuMobile, setOpenedNavigationMenuMobile } = useNavigationMenuContext();
  const isOlist = OLIST_HOST_NAME.includes(window.location.hostname);
  const rebrandedRoutes = ['/org/affiliate/members'];
  const customization = getLocalInfo<OrganizationCustomization>(ORGANIZATION_CUSTOMIZATION_KEY);
  const isOrganizationAdmin = useIsOrganizationAdmin();

  const isInitialShowcasePage = window.location.pathname.endsWith('/showcase');
  const isShowcaseTemplatePage = window.location.pathname.includes('/showcase/template');

  const isNewShowcase =
    (currentOrganization?.integration?.subtype === 'vtex_io' ||
      currentOrganization?.integration?.type === Integrations.Yampi) &&
    !isInitialShowcasePage &&
    !isShowcaseTemplatePage &&
    window.location.pathname.startsWith(
      SHOWCASE_PATH.replace(':organizationSlug', currentOrganization?.slug),
    ) &&
    isOrganizationAdmin;

  const {
    data: dataOrganization,
    loading: loadingOrganization,
    refetch: refetchOrganization,
  } = useQuery<OrganizationDetailsQuery>(ORGANIZATION_DETAILS, {
    fetchPolicy: 'no-cache',
    skip: !!currentOrganization?.integration,
    onCompleted: data => {
      if (data.organizationDetails) {
        selectCurrentOrganization(data.organizationDetails);
      }
    },
  });

  const showFirstSteps = dataOrganization?.organizationDetails.showFirstSteps;

  React.useEffect(() => {
    if (!currentOrganization?.id && window.location.hostname !== 'localhost')
      history.push('/dashboard');
  });

  useEffect(() => {
    setOpenedNavigationMenuMobile(false);
  }, [isNewShowcase, setOpenedNavigationMenuMobile]);

  // return <AdminGamificationV2 />;

  const maryKayBankStatus = getBraspagBankStatus(pendencies);

  return (
    <Flex flexDirection="row" minHeight="100%" width="100%">
      {!showFirstSteps &&
        maryKayBankStatus !== '4' &&
        (isNewShowcase && !isMobile ? (
          <NavSidebarWrapper isOpen={openedNavigationMenuMobile} isNewShowcase={isNewShowcase}>
            <FloatingNavSidebarStyled
              isOpen={openedNavigationMenuMobile}
              isNewShowcase={isNewShowcase && isDesktop}
            >
              <FloatingNavSidebar showMenu={showMenu} setShowMenu={setShowMenu} />
            </FloatingNavSidebarStyled>
          </NavSidebarWrapper>
        ) : (
          <NavSidebarWrapper isOpen={openedNavigationMenuMobile}>
            <FloatingNavSidebarStyled isOpen={openedNavigationMenuMobile}>
              <NavSidebar />
            </FloatingNavSidebarStyled>
          </NavSidebarWrapper>
        ))}

      <CountdownContainerContextProvider>
        <DashboardFlex>
          <HeaderWrapper>
            <FloatingHeader fluid showMenu={showMenu}>
              <Header title={title} hideHeaderTitle={hideHeaderTitle} />
            </FloatingHeader>
          </HeaderWrapper>

          <Flex
            width="100%"
            height={'100%'}
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            backgroundColor={rebrandedRoutes.includes(location.pathname) ? '#FFF' : '#F0F1F5'}
          >
            {showFirstSteps && dataOrganization && (
              <AdminGamification
                onRefetchOrganization={refetchOrganization}
                organization={dataOrganization?.organizationDetails}
              />
            )}

            <ErrorBoundary>
              <Switch>
                {!showFirstSteps &&
                  routes.map((route: any, i: any) => {
                    return (
                      <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any): JSX.Element => {
                          return <route.component {...props} routes={route.routes} />;
                        }}
                      />
                    );
                  })}
              </Switch>

              {/* {!isOlist && <Spacer mt="32px" />} */}
            </ErrorBoundary>
          </Flex>
        </DashboardFlex>

        {!isOlist && !customization?.hideFooter && <DashboardFooter />}
      </CountdownContainerContextProvider>
    </Flex>
  );
};

export default DashboardLayout;
