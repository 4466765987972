import React, { useEffect, useRef } from 'react';
import Gleap from 'gleap';
import { useQuery } from '@apollo/client';
import { OrganizationDetailsQuery } from '@plug/graphql/queries/organization-details';
import { ORGANIZATION_DETAILS } from '@plug/domains/organization/graphql/queries/organization-details';
import { useLocation } from 'react-router-dom';
import { USER_LOCAL_STORAGE_KEY } from '@plug/config/consts';

import cookie from 'js-cookie';

export const GleapComponent = () => {
  const { data: organizationDetailsData } = useQuery<OrganizationDetailsQuery>(
    ORGANIZATION_DETAILS,
  );
  const location = useLocation();
  const gleapInitializedRef = useRef<boolean>(false);
  const user = JSON.parse(decodeURIComponent(cookie.get(USER_LOCAL_STORAGE_KEY) || '{}'));

  useEffect(() => {
    if (!organizationDetailsData) return;

    const isAdmin =
      organizationDetailsData?.organizationDetails?.organizationRole[0]?.name === 'ADMIN';

    if (isAdmin) {
      if (!gleapInitializedRef.current) {
        Gleap.initialize('XcCEJgBTiJKFqOM4yTYBeJqc8741koTx');
        Gleap.identify(user?.id, {
          email: user?.email,
          name: user?.username,
          companyName: organizationDetailsData?.organizationDetails?.name,
          companyId: organizationDetailsData?.organizationDetails?.id,
          customData: {
            domain: organizationDetailsData?.organizationDetails?.domain,
            integration: organizationDetailsData?.organizationDetails?.integration?.type,
          },
        });
        gleapInitializedRef.current = true;
      }
    } else {
      if (gleapInitializedRef.current) {
        Gleap.destroy();
        gleapInitializedRef.current = false;
      }
    }
  }, [organizationDetailsData, location, user]);

  return <></>;
};
