import gql from 'graphql-tag';

export const ORGANIZATION_DETAILS = gql`
  query organizationDetails {
    organizationDetails {
      id
      contactEmail
      name
      active
      hasMember
      slug
      showFirstSteps
      domain
      organizationRole {
        name
      }
      integration {
        type
        status
        createdAt
        updatedAt
        asSaas
      }
    }
  }
`;

// type OrganizationDetails = {
//   organizationDetails: Organization;
// };

export type OrganizationDetailsQuery = {
  organizationDetails: Organization;
};
